.user-data-page-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .user-data-sections-wrap {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        @media(max-width: 1000px) {
            flex-direction: column;
        }
    }
    .user-data-wrap {
        width: 100%;
        padding: 20px;
        border-right: 1px solid $shade-dark;
        @media(max-width: 1000px) { 
            border-right: none;
        }
        .select-field-wrap {
            margin-bottom: 10px;
            select {
                width: 200px;
                height: 30px;
                border-radius: 3px;
            }
        }
    }
    .user-data-ps-wrap{
        padding: 20px;
        width: 400px;
    }
    .user-page-subtitle {
        margin-bottom: 10px;
    }
}