.responsive-filter-menu-wrap { 
    display: none;
  
    &.open {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        gap: 5px;
        padding: 20px;
        top: 0;
        bottom: 0;
        left: 0;

        height: 100%!important;
        width: 300px;
        z-index: 10000;
        background-color:whitesmoke;//#FF5722;
       
    }
}