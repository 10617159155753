.user-message-wrap {
  //height: 100px;
  margin: 20px;
  border: 1px solid $light-border;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  .user-message-checkbox-wrap {
    width: 50px;
    height: 100%;
  }
  .user-message-message-wrap {
    border-right: 1px solid $shade-light;
    border-left: 1px solid $shade-light;
    width: 100%;
    height: 100%;
    margin: 0 10px;
    padding: 0 10px;
    div {
      width: 100%;
    }
  }
  .user-message-buttons-wrap {
    div {
      margin-left: 20px;
    }
  }
}

.new_lines {
  white-space: pre-wrap;
}

.bolded {
  font-weight: bold;
}
