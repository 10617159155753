.checkboxes-dropdown-wrap {
    .checkboxes-dropdown-header {
        width: 100%;
        padding: 5px 10px;
        background-color: $accentColor2;
        color: white;
        // border-bottom: 1px solid $shade-light;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            opacity: 0.8;
        }
    }
    
    .checkboxes-visible {
        transition: 0.5s;
        padding: 5px 10px;
        font-size: 12px;
    }
}