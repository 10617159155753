.small-info-box {
    p{
    font-size: 12px;
    margin: 5px 5px;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    min-width: 50px;
    font-weight: bold;
    &.big {
        font-size: 18px;
        padding: 3px;
    }
}
}