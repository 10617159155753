.new-listing-success-wrap {
    // width: 800px;
    
    // background-color: bisque;
    margin: 0 auto;
    
    .new-listing-success-message{
        margin-bottom: 30px;
        font-size: 20px;
        p{
            margin: 10px;
        }
    }
    
    .new-listing-success-button-wrap{
        width: 100%;
        .extra-wide-button {
            width: 250px!important;
        }
    }
    .info-box-wrapper {
        margin-bottom: 20px;
    }
}