@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Roboto:wght@300;400;500;700&display=swap');

$font : 'Roboto';
$regularWeight: 400;
$light: 300;
$bold: 500;
$bolder: 700;

$accentColor1: #0073F0;
$accentColor0: #0073F0;
$accentColor2:#FF5722;
$confirmation: #03BF29;
$premium: #7800F0;
$background-color1: #F8F8F8;
$accentColor1: #CECDCD;
$shade-light: #979696;
$shade-dark: #3E3E3E;
$light-border: rgb(186, 186, 186);
$light-background: rgb(251, 248, 248);
$lightest-border: gainsboro;

$breakpoint-first: 1200px;
$breakpoint-tablet: 768px;
