.mock-ad {
  width: 200px;
  height: 300px;
  border: 1px solid gray;
  margin: 20px 0;
  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  
  }
}
