.select-field-wrap {
    display: flex;
    &.row {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        label {
            margin-right: 10px;
        }
    }
    &.column {
        flex-direction: column;
        align-items: space-between;
        justify-content: flex-start;
        label{
            margin-bottom: 10px;
        }
    }
    .select-field {
        background-color: white!important;
        color: $shade-dark;
        border:1px solid #bebebe;
        border-radius: 5px;
        outline: none;
        padding: 3px;
        font-size: 11px;
        padding:5px;

    }
}