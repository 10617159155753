.type-select{
  width:100%;
   margin: 5px 0;
}

.main-search-box-type-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .select-field {
    width: 100% !important;
    height: 32px;
    margin: 5px 0;
  }
  
}

.main-search-box-wrap {
  padding: 10px;
  background-color: white;
  color: $shade-dark;
  overflow-y: auto;
  overflow-x: hidden;
  p {
    margin: 5px 0;
  }

  .main-search-box-selects-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .select-field {
      width: 120px;
      height: 32px;
      margin: 5px 0;
    }
    .radio-group-wrap {
      display: flex;
      flex-direction: row;
    }
  }
  .search-button {
    margin-top: 5px;
    width: 100%;
    padding: 5px 0;
    font-size: 12px;
  }

  .span-style {
    width: 45%;
  }
}
