@import "../node_modules/react-perfect-scrollbar/dist/css/styles.css";
@import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@import "./variables";
@import "./general";
@import "./components/components";
@import "./pages/pages";

.App {
  position: relative;
  // min-height: 100vh;
  // width: 100vw;
  .content-wrap {
    // padding-bottom: 400px;
    width: 100%;
    // height: calc(100vh - 414px);;

  
   
    // // min-height: 200vh;
  }
  .app-content-wrap {
    // width: 100%;
    padding-bottom: 20px;
    min-height: 100vh;
  }

  .main-page-image-search {
    // background-image: src('../../assets/images/homebck.png');
    height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .image-dark-overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      padding: 0;
      margin: 0;
    }
    p {
      font-size: 40px;
      max-width: 400px;
    }
    .main-page-search-box {
      padding: 10px;
      border: 1px solid $light-border;
      border-radius: 4px;
      width: 270px;
      height: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 100px;
      background-color: white;
      @media(max-width: 950px) {
        display: none;
      }
      p {
        font-size: 16px;
      }
      .select-field {
        width: 120px;
      }
    }
  }
  .filter-sidebar-wrapper {
    left: 0;
    margin-top: 50px;
    @media(max-width:1200px) {
      .user-navigation-wrap {
        display: none;
      }
    }
  }
  .side-menu-ads-sidebar-wrapper {
    width: 200px;
    right: 0;
    top: 0px;
    margin: 0;
    margin-top: 50px;
    
    @media(max-width:700px) {
      display: none;
      width: unset;
      left:0;
    }
 
    .navigation-wrapper {
      margin: 0;
      padding: 0;
      background-color: $accentColor0;
      @media(max-width:1200px) {
        display: none;
      }
      ul {
        align-items: flex-start;

        li {
          color: white;
          border-bottom: 1px solid white;
          padding: 10px;
          width: 100%;
          a {
            color: white;
          }
        }
      }
    }
  }
}


* {
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb
	{
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 6px rgba(189, 189, 189, 0.3);
	  background-color: rgb(153, 153, 153);
      cursor: pointer;
	}
}