.information-page-wrap { 
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .information-page-background-image-wrap {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 400px;
        border-radius: 10px;
        // background-image: url('../../assets/images/kredit.jpg');
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin: 30px 0 ;
       @media(max-width: 570px) {
        font-size: 25px;
       }
       @media(max-width: 320px) {
        font-size: 20px;
       }
    }
    .information-page-content {
        padding: 0 50px;
        @media(max-width:1000px) {
            padding: 0 20px;
        }
    }
}