@import "./components/basic-info/basic-info";
@import "./components/contact/contact";
@import "./components/locations/locations";

.listing-page-wrap {
  // height: 100%;
  // background-color:$background-color1;
  .font-light-gray {
    color: $shade-light;
  }
  .page-wrap-header {
    border-top: 2px solid $accentColor0;
    border-bottom: 1px solid $light-border;
    padding: 5px 0;
    width: 100%;
    .page-wrap-icons {
      margin-bottom: 10px;
      .page-wrap-icon {
        margin-left: 10px;
      }
    }
  }

  .listing-page-contact-publisher {
    margin: 10px 0;
    width: 100%;
    min-width: 300px;
  }
  .listing-page-gallery-basic-info-wrap {
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-height: 600px;
    overflow: hidden;
    @media(max-width:915px) {
      overflow: visible;
      flex-direction: column;
      max-height: unset;
      align-items: center;
      .listing-page-basic-info {
        width: 100%;
        .listing-page-price {
          .uppercase {
            font-size: 18px!important;
          }
        }
      }
    }
     @media(max-width:550px) {
      margin: 0;
      .listing-page-contact-publisher {
        width: 100%;
      }
      .listing-page-basic-info { 
        margin: unset;
        width: 100%;
      }
     }
  }
  .listing-page-basic-info {
    border: 1px solid $light-border;
    padding: 10px;
    margin: 20px 0 20px 20px;
    border-radius: 4px;
    .font-light-gray {
      margin-right: 10px;
    }
    .listing-page-price {
      p {
        font-size: 25px !important;
        padding: 5px 10px;
      }
    }
    .listing-card-amenities {
      width: 100%;
      margin: 20px 0;
      border-top: 1px solid $lightest-border;
      border-bottom: 1px solid $lightest-border;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
  .listing-page-location-legend-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 860px) {
      flex-direction: column;
      .listing-page-map-wrap {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
      }
      .section-heading-content {
        height: 100%;
        width:100%;
      
      }
      .listing-page-location-legend {
        margin-left: 0;
        // height: 800px;
      }
      
    }
   
  } 
  .listing-page-location-legend {
    height: 600px;
    width: 100%;
    flex: 1;
    margin-left: 20px;
    border-left: 3px solid $accentColor0;
    flex-wrap: wrap;
    .icon-text-info {
      border: 1px solid $accentColor0;
      border-radius: 2px;
      padding: 5px;
      color: $accentColor0;
      min-width: 45%;
      margin: 5px 10px;
      width: 200px;
      .icon-text-icon {
        margin-right: 10px;
      }
      .blue-b {
        padding: 5px 10px;
      }
    }
  }
  .listing-page-map-wrap {
    height: 300px;
    width: 400px;
    border: 1px solid $shade-light;
  }

  .carousel {
    max-height: 600px;
  }
  .contact-publisher {
    @media(max-width:550px) {
      flex-direction: column;
      gap: 20px;
    }
    
  }
}
