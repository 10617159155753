.main-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 100%;
    &.shadow {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;    
        margin-bottom: 30px;
    }
    .header-nav-bar, .header-logo-nav-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        z-index: 1000;
        padding: 0 20px;
        .regular-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 20px;
            @media(max-width:1200px) {
                display:none;
            }
        }
    }
    .header-nav-bar {
        .back-button {
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            width: 100px;
            visibility: hidden;
            &.visible {
                visibility: visible;
            }
        }
        // justify-content: flex-end;
    }
    
}