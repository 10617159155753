.login-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    .login { 
        width: 250px;
    }
    h2 {
        margin-bottom: 30px;
        font-weight: bold;
       // text-align: center;
    }
    .login-button {
        align-self: center;
        width: 100%;
    }
    .social-login {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
    .fb-button {
        background-color: #1877f2;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 10px 20px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        width: 250px;
        font-size: 14px;
        height: 39px!important;
    }
    button, .gg-button {
        width: 250px;
        height: 39px!important;
        border-radius: 5px;
        padding: 10px 20px;
        display: flex!important;
        // gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .check-box-field-wrapper {
        justify-content: flex-end;
       // background-color: $accentColor0;
    }
    
    p {
        margin-top: 20px;
    }
    
    .login-icons-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .login-icon {
            color: $accentColor0;
            
            &:hover { 
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}