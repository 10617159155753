.main-page-wrapper  {
    // margin: 50px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        
        gap: 20px;
        .main-page-content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            
            gap: 20px;
            .main-page-listings {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                flex-wrap:nowrap;
                flex:1;
              
            }

            // flex-wrap: wrap;
            // flex-direction: row;
            // justify-content: flex-start;
            // gap: 20px;
            .blog-shotcuts{
              flex: 1;
            //   background-color: pink;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap:20px;
              flex-wrap: wrap;
              padding: 20px;
              &:last-child {
                justify-self: flex-start;
                align-self: flex-start;
              }
            }
            @media (min-width: $breakpoint-first) { 
            }
    }

    // .main-page-content-wrapper {
    //     margin: auto;
    //     max-width: 1000px;
    //     overflow: auto;
    //     flex-wrap: wrap;
    //     margin-top: 30px;
    //     padding: 20px 20px 20px 20px;
        
        
    //     .blog-shotcuts  {
            
    //         margin: 20px 0;
    //         flex-wrap: wrap;
    //         overflow: auto;
    //         width: 950px;
    //         .image-title-button-wrapper {
    //             margin-bottom: 20px;
    //             margin-left: 10px;
    //         }
    //     }
    // }
}