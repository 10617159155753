.footer-wrapper{
    width: 100%;
    background-color: $accentColor0;
    color: white;
    width: 100%;
    height: 300px;
    // position: absolute;
    // bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    clear: both;
    @media(max-width: 580px) {
        flex-direction: column;
        height: unset;
        gap: 20px;
        align-items: flex-start;
    }
    .navigation-wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        flex-wrap: wrap;
      
        .menu-title {
            font-size: 16px;
            font-weight: bold;
        }
        ul {
            gap: 10px;
            display: flex;
            align-items: flex-start!important;
            justify-content: flex-start!important;
            flex-wrap: wrap;
            // overflow: auto;
            flex:1;
            height: 100%;
            width: 100%;
            // background-color: pink;
            li{
                // flex: 1;
                a{
                color: white;
                }
            }
        }
    }
}