@import './components/listing-card-amenities/listing-card-amenities';
@import './components/listing-loved-by/listing-loved-by';
.listing-card-wrapper {
    // flex:1;
   width: 300px!important;
    height: 320px;
    border-radius: 5px;
    border: 1px solid $light-border;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   
    &.premium {
        background-color: rgb(255, 147, 93);
        color: black;
    }
   
    .listing-card-image-panel {
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 200px;
      
        .listing-card-transparent-back  {
            background-color: rgba($shade-dark,0.8);
            
            border-top: 1px solid white;
            color: white;
            padding: 5px;
            position: absolute;
            bottom: 0;
            width:100%;
        }
        .pics-num {
            font-size: 16px;
            font-weight: bold;
            padding-left: 5px;
        }
       .small-name {
           font-size: 12px;
           padding-left: 5px;
       }
    }
    .listing-card-info {
        color: black;
        padding: 5px;
        height: 100%;
        .listing-card-amenities  {
            margin-top: 20px;
            padding-bottom: 5px;
        }
    }
}