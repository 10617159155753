.image-title-button-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 450px;
    height: 200px;
    border-radius: 5px;
    @media(max-width: 1095px) {
        width: 350px;
    }
    @media(max-width: 889px) {
        width: 250px;
    }
    @media(max-width: 600px) {
        // width: 350px;
        width:100%;
    }
}