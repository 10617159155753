.map-size {
  width: "100%";
  height: "500px";
}
.new-listing-page-map-wrap{
  .leaflet-container {
    width: 100%;
    min-height: 300px;
    height: 100%;
  }
}