@import "./login/login";
@import "./main-page/main-page";
@import "./new-listing/new-listing";
@import "./listings-list-page/listings-list-page";
@import "./new-listing-success/new-listing-success";
@import "./user-panel/user-panel";
@import "./listing-page/listing-page";
@import "./information-page/information-page";
@import "./new-listing-data/new-listing-data";
@import "./transaction-page/transation-page";
@import "./statistics/statistics";
@import "./listing-not-found/listing-not-found";
@import './advertisment-offer/advertisment-offer-page';

.page-wrap {
  // max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 50px 20px;
}
