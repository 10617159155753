.not-found-wrap {
  // width: 800px;

  // background-color: bisque;
  margin: 0 auto;

  .not-found-message {
    margin-bottom: 30px;
    font-size: 20px;
    p {
      margin: 10px;
    }
  }
}
