@import "./components/user-data/user-data";
@import "./components/contact-support/contact-support";
@import "./components/user-message/user-message";
@import "./components/user-message-list/user-message-list";
@import "./components/user-inbox/user-inbox";
@import "./components/blocked-users/blocked-users";
@import "./components/user-notification-list/user-notification-list";
@import "./components/user-notification/user-notification";

.user-panel-wrap {
  .user-panel-content {
    background-color: $background-color1;
    border: 1px solid $light-border;
    color: $shade-dark;
    border-radius: 3px;
    padding: 10px;
    margin: 0 5px;
    // width: 100%;
    display: flex;
    flex:1;
   
  }
  .announcements-list {
    // height: 800px;
    .title {
      margin-bottom: 20px;
    }
    .user-announcement {
      margin-bottom: 20px;
    }
  }
}
