.navigation-wrapper {
    margin-right: 10px;
    &.row {
        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
    &.column {
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    }
    ul{
        list-style-type: none;
        li {
            margin-right: 30px;
            color: $accentColor1;
            font-weight: 700;
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
            a {
                color:#0073F0;
            }
        }
    }
}