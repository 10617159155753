.responsive-navigation-wrap { 
    display: none;
  
    &.open {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: fixed;
        gap: 5px;
        padding: 10px 20px;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        // height: 100%;
        width: 250px;
        z-index: 10000;
        background-color:whitesmoke;
        overflow: auto;
        .responsive-navigation-top {
            display: flex; 
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            // padding: 10px;
        }
        .responsive-navigation { 
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            padding-right: 0px;
            margin-bottom: 20px;
        }
        .language-select, .resp-menu-btn {
            width: 200px;
            // margin-top: 20px;
        }
        // .css-jl8pyr-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        //     min-height: unset;
        // }

        .user-navigation-wrap {
            width: 100%!important;
            ul {
                margin: unset;
                .user-navigation-link {
                    
                    &.active {
                        li {
                            color: white!important;
                            span{
                                color: white!important;
                            }
                        }
                       
                       
                    }
                }
                
                li {
                    width:100%;
                    margin: unset;
                    justify-content: flex-start;
                }
            }
        }
        .navigation-wrapper {
            width: 100%!important;
            // width:50%;
            
            ul {
                width: 100%!important;
                align-items: flex-start;
                gap: 20px;
                margin: 20px 0;
                
                .profile-navigation { 
                    // width: 100%!important;
                    border: unset;
                    ul {
                        margin: unset;
                        li {
                            margin: unset;
                            justify-content: flex-start;
                        }
                    }
                }
            }
          
           
        }
    }
}