.button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    border-radius: 3px;
    width: 150px;
    color: white;
   
    &:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    &.primary {
        background-color:#0073F0;
    }
    &.secondary {
        background-color: $accentColor2;
    }
    &.gray {
        background-color: $shade-light;
    }
}