.navigation-wrapper {

    .profile-navigation {
        padding: 3px;
        border: 1px solid $accentColor0;
        border-radius: 3px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: 0.2s;
        max-width: 200px;
        color:$accentColor0;
        .profile-navigation-username {
            text-overflow: ellipsis;
            overflow: hidden; 
            width: 140px; 
            white-space: nowrap;
        
        }
        .profile-navigation-menu {
            position: absolute;
            top: 35px;
            transition: 0.2s;
            border: 1px solid $accentColor0;
            padding: 3px;
            border-radius: 4px;
            visibility: hidden;
            color: $shade-dark;
            // min-height: 50px;
            background-color: white;
            width: 100%;
            ul {
                margin: unset;
            }
            li {
                color:$shade-dark;
                border-bottom: $shade-light;
                width: 100%;
                margin-bottom: 5px;
            }
            &:last-child {
                li {border-bottom: none;}
            }
            &.visible {
                visibility: visible;
            }
        }
    }
}