.listing-list-page-wrapper {

    .listing-list-page-content-header {
        width: 100%;
        padding: 30px 0;
        border-top: 1px solid $light-border;
        .orange {
            margin-left: 5px;
            font-weight: bold;
        }
        .green-select {
            .select-field {
                border: 1px solid lime!important;
            }
        }
    }
    .listings-list-pagination-wrap {
        height: 1200px;
        width: 100%;
        .listings-list-wrap {
            overflow: auto;
            width: 100%;
            // margin: 20px 0;

        //    &.background-gray{
        //     background-color: $light-background;
        //     border: 1px solid $lightest-border;
        //     padding: 20px 10px;
        //     border-radius: 5px;
        //    }
        }
    }
    
    
}