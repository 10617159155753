.promoted-listings-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .promoted-listings {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @media(max-width: 1430px) {
            justify-content: flex-start;
            gap:20px;
        }
        &:last-child {
            justify-content: flex-start;
        }
    }

}