.info-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $background-color1;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  color: $shade-dark;
  min-width: 200px;
  gap: 10px;

  span {
    font-weight: bold;
    color: $accentColor2;
  }
  .black-text {
    color: black;
  }

  .red-text {
    color: red;
  }
}
