@import "./components/image-upload/image-upload";
@import "./components/video-upload/video-upload";
@import "./components/basic-info/basic-info";
@import "./components/contact/contact";
@import "./components/additional-data/additional-data";
@import "./components/checkbox-fields/checkbox-fields";

.new-listing-data {
  width: 100%;
  // background-color: pink;
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    padding: 0;
  }

  .input-field-icon {
    height: 56px !important;
    width: 300px !important;
    .additional-info {
      height: 56px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
    }
  }
  .general-info-box-wrap {
    width: 100%;
    display: flex;
    // align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    // background-color: red;
    .new-listing-section {
      margin-bottom: 10px !important;
    }
    .general-info-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      color: white;
      background-color: $accentColor2;
      padding: 0 10px;
      font-size: 13px;
    }
  }

  select,
  .input-field-wrap {
    border-radius: 3px;
    // padding: 5px;
    height: 56px;
    width: 300px;
  }
  input {
    width: 200px;
  }
  .listing-general-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }
  .listing-general-title {
    font-weight: bold;
  }
  .listing-general-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    // background-color: blue;
    .listing-general-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      .select-field-wrap {
        select {
          width: 300px;
          // padding: 5px;
        }
      }
    }
    .new-listing-basic-data {
      width: 100%;
      // background-color: pink;
    }
    .new-listing-location {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
    }
    .new-listing-item-box {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      // background-color: cyan;
      // flex-wrap: wrap;
      margin-bottom: 10px;
    }
    .new-listing-checkboxes-box {
      display: grid;

      grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
      .checkbox-wrap {
        flex: 1 1 180px;

        // width: 100%;
        // background-color: darkcyan;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        p {
          display: flex;
          flex: 1;
        }
      }
    }
    .new-listing-contact-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;
      .new-listing-contact-info-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        select {
          width: 400px;
        }
      }
    }
  }
  .button-wrapper {
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 20px;
  }

  .new-listing-page-map-wrap {
    height: 300px;
    width: 800px;
    border: 1px solid $shade-light;
  }
}
