.user-navigation-wrap {
    width: 250px;
    .user-navigation-link {
        border: 1px solid $shade-light;
        background-color: $background-color1;
        color: $shade-dark;
        padding: 5px;
        @media(max-width:1200px) {
            border:unset;
            background-color: transparent;
            color: $accentColor0;
            padding: 5px 0;
            &.active {
                background-color: transparent;
                color:  $shade-dark;
                .blue{
                    color:  $accentColor0;
                }
            }
            li {
                color:  $accentColor0;
            }
        }
        &.active {
            background-color: $accentColor0;
            color: white;
            .blue{
                color: white;
            }
        }
        .blue {
            margin-right: 10px;
        }
    }
}