.new-listing-wrapper {
    
    height: 100%;
    color: black;
    p {
        margin: 10px 0;
    }
    .new-listing-icons-wrap {
        margin-top: 20px;
        .icon-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px 10px;
        }
        .icon-text-icon{
            width: 50px;
            height: 50px;
            color: $accentColor0;
            .MuiSvgIcon-root {
                font-size: 50px;
            }
        }
    }
    .new-listing-instructions {
        padding: 5px ;
        background-color: $accentColor0;
        color: white;
        .white {
            margin-left: 5px;
        }
    }
    
}