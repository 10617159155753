.logo-wrapper {
    width: 50px;
    background-color: #0073F0;
    
    img {
        width: 100%;
        height: 100%;
        
        // fill: $accentColor1;
        // color: $accentColor1;
    }
}