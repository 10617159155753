.listing-overview-item-panel-wrap {
  .listing-overview-item-bottom-panel {
    width: 100%;
    background-color: $shade-dark;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px;
    color: white;
    .listing-overview-option-item {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      p {
        margin-right: 10px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  .listing-overview-item-wrap {
    &.blured {
      opacity: 0.6;
    }
    // width: 950px;
    height: 300px;
    background-color: $light-background;
    border-radius: 4px;
    margin: 30px 0;
    border: 1px solid $lightest-border;
    border-radius: 5px;
    border-left: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width:800px) {
      flex-direction: column;
      // height:unset;
      width: 100%;
      height: 100%;
      max-height: 600px;

    }

    .listing-card-image-panel {
      // height: 100%;
      border-radius: 5px 0 0 5px;
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 100%;
      @media (max-width:800px) {
        
          width: 100% !important;;
          height: 300px!important;
        
      }
      @media (max-width:700px) {
        
        width: 100% !important;;
        height: 200px!important;
      
    }
      .listing-card-transparent-back {
        background-color: rgba($shade-dark, 0.8);
        color: white;
        padding: 10px;
        border-radius: 0 0 0 5px;
        border-top: 1px solid white;
      }
    }
    .listing-overview {
      padding: 10px 20px;
      width: 100%;
      height: 100%;
      @media (max-width:800px) { 
        // height: 800px;
      }
      .listing-overview-price {
        p {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
      .listing-overview-box {
        // background-color: wheat;
        // height: 80%;
        // height: 200px;
        border: 1px solid $lightest-border;
        padding: 5px;
        flex: 1;
        margin: 5px 0;
        .listing-card-amenities {
          border-bottom: 1px solid $lightest-border;
          padding: 5px;
          .small {
            margin-right: 20px;
          }
        }
        .listing-overview-description-loved-wrap {
          width: 100%;
          height: 100%;
          // background-color: cadetblue;
          .listing-overview-description {
            padding: 10px 0;
            font-size: 12px;
            p {
              display: -webkit-box;
              max-width: 80%;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          .loved-by-wrap {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.hide {
  display: none;
}
