.user-panel-contact-support-wrap {
    max-width: 500px;
    width:100%;
    // background-color: $confirmation;
    padding: 0;
    .user-panel-contact-support-title {
        margin-bottom: 20px;
    }
    .input-field-wrapper {
        width: 100%;
        // background-color: purple;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    textarea, .input-field-icon {
        width: 300px;
        margin-bottom: 10px;
    }
}