@import "../node_modules/react-perfect-scrollbar/dist/css/styles.css";
@import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Roboto:wght@300;400;500;700&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  font-weight: 400;
}

html, body {
  padding: 0;
  margin: 0;
}

.clickable:hover {
  opacity: 0.8;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.xs {
  font-size: 11px;
}

.small {
  font-size: 12px;
}

.pre-regular {
  font-size: 14px;
}

.regular {
  font-size: 16px;
}

.medium {
  font-size: 18px;
}

.big {
  font-size: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.white {
  color: white;
}

.black {
  color: black;
}

.light-gray {
  color: #979696;
}

.orange {
  color: #FF5722;
}

.blue {
  color: #0073F0;
}

.green {
  color: #03BF29;
}

.green-b {
  background-color: #03BF29;
  color: white;
}

.orange-b {
  background-color: #FF5722;
  color: white;
}

.blue-b {
  background-color: #0073F0;
  color: white;
}

.purple-b {
  background-color: #7800F0;
  color: white;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex.a-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.flex.a-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex.a-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.flex.a-sb {
  -webkit-box-align: space-between;
      -ms-flex-align: space-between;
          align-items: space-between;
}

.flex.a-evenly {
  -webkit-box-align: space-evenly;
      -ms-flex-align: space-evenly;
          align-items: space-evenly;
}

.flex.jc-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex.jc-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex.jc-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex.jc-sb {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex.jc-evenly {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.navigation-wrapper {
  margin-right: 10px;
}

.navigation-wrapper.row ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navigation-wrapper.column ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navigation-wrapper ul {
  list-style-type: none;
}

.navigation-wrapper ul li {
  margin-right: 30px;
  color: #CECDCD;
  font-weight: 700;
}

.navigation-wrapper ul li:hover {
  opacity: 0.8;
  cursor: pointer;
}

.navigation-wrapper ul li a {
  color: #0073F0;
}

.navigation-wrapper .profile-navigation {
  padding: 3px;
  border: 1px solid #0073F0;
  border-radius: 3px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  max-width: 200px;
  color: #0073F0;
}

.navigation-wrapper .profile-navigation .profile-navigation-username {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 140px;
  white-space: nowrap;
}

.navigation-wrapper .profile-navigation .profile-navigation-menu {
  position: absolute;
  top: 35px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 1px solid #0073F0;
  padding: 3px;
  border-radius: 4px;
  visibility: hidden;
  color: #3E3E3E;
  background-color: white;
  width: 100%;
}

.navigation-wrapper .profile-navigation .profile-navigation-menu ul {
  margin: unset;
}

.navigation-wrapper .profile-navigation .profile-navigation-menu li {
  color: #3E3E3E;
  border-bottom: #979696;
  width: 100%;
  margin-bottom: 5px;
}

.navigation-wrapper .profile-navigation .profile-navigation-menu:last-child li {
  border-bottom: none;
}

.navigation-wrapper .profile-navigation .profile-navigation-menu.visible {
  visibility: visible;
}

.user-navigation-wrap {
  width: 250px;
}

.user-navigation-wrap .user-navigation-link {
  border: 1px solid #979696;
  background-color: #F8F8F8;
  color: #3E3E3E;
  padding: 5px;
}

@media (max-width: 1200px) {
  .user-navigation-wrap .user-navigation-link {
    border: unset;
    background-color: transparent;
    color: #0073F0;
    padding: 5px 0;
  }
  .user-navigation-wrap .user-navigation-link.active {
    background-color: transparent;
    color: #3E3E3E;
  }
  .user-navigation-wrap .user-navigation-link.active .blue {
    color: #0073F0;
  }
  .user-navigation-wrap .user-navigation-link li {
    color: #0073F0;
  }
}

.user-navigation-wrap .user-navigation-link.active {
  background-color: #0073F0;
  color: white;
}

.user-navigation-wrap .user-navigation-link.active .blue {
  color: white;
}

.user-navigation-wrap .user-navigation-link .blue {
  margin-right: 10px;
}

.responsive-navigation-wrap {
  display: none;
}

.responsive-navigation-wrap.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  gap: 5px;
  padding: 10px 20px;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  z-index: 10000;
  background-color: whitesmoke;
  overflow: auto;
}

.responsive-navigation-wrap.open .responsive-navigation-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.responsive-navigation-wrap.open .responsive-navigation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 0px;
  margin-bottom: 20px;
}

.responsive-navigation-wrap.open .language-select, .responsive-navigation-wrap.open .resp-menu-btn {
  width: 200px;
}

.responsive-navigation-wrap.open .user-navigation-wrap {
  width: 100% !important;
}

.responsive-navigation-wrap.open .user-navigation-wrap ul {
  margin: unset;
}

.responsive-navigation-wrap.open .user-navigation-wrap ul .user-navigation-link.active li {
  color: white !important;
}

.responsive-navigation-wrap.open .user-navigation-wrap ul .user-navigation-link.active li span {
  color: white !important;
}

.responsive-navigation-wrap.open .user-navigation-wrap ul li {
  width: 100%;
  margin: unset;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.responsive-navigation-wrap.open .navigation-wrapper {
  width: 100% !important;
}

.responsive-navigation-wrap.open .navigation-wrapper ul {
  width: 100% !important;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 20px;
  margin: 20px 0;
}

.responsive-navigation-wrap.open .navigation-wrapper ul .profile-navigation {
  border: unset;
}

.responsive-navigation-wrap.open .navigation-wrapper ul .profile-navigation ul {
  margin: unset;
}

.responsive-navigation-wrap.open .navigation-wrapper ul .profile-navigation ul li {
  margin: unset;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.responsive-filter-menu-wrap {
  display: none;
}

.responsive-filter-menu-wrap.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: absolute;
  gap: 5px;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100% !important;
  width: 300px;
  z-index: 10000;
  background-color: whitesmoke;
}

.listing-card-wrapper {
  width: 300px !important;
  height: 320px;
  border-radius: 5px;
  border: 1px solid #bababa;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.listing-card-wrapper.premium {
  background-color: #ff935d;
  color: black;
}

.listing-card-wrapper .listing-card-image-panel {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
}

.listing-card-wrapper .listing-card-image-panel .listing-card-transparent-back {
  background-color: rgba(62, 62, 62, 0.8);
  border-top: 1px solid white;
  color: white;
  padding: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.listing-card-wrapper .listing-card-image-panel .pics-num {
  font-size: 16px;
  font-weight: bold;
  padding-left: 5px;
}

.listing-card-wrapper .listing-card-image-panel .small-name {
  font-size: 12px;
  padding-left: 5px;
}

.listing-card-wrapper .listing-card-info {
  color: black;
  padding: 5px;
  height: 100%;
}

.listing-card-wrapper .listing-card-info .listing-card-amenities {
  margin-top: 20px;
  padding-bottom: 5px;
}

.listing-overview-item-panel-wrap .listing-overview-item-bottom-panel {
  width: 100%;
  background-color: #3E3E3E;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px;
  color: white;
}

.listing-overview-item-panel-wrap .listing-overview-item-bottom-panel .listing-overview-option-item {
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.listing-overview-item-panel-wrap .listing-overview-item-bottom-panel .listing-overview-option-item p {
  margin-right: 10px;
}

.listing-overview-item-panel-wrap .listing-overview-item-bottom-panel .listing-overview-option-item:hover {
  cursor: pointer;
  opacity: 0.8;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap {
  height: 300px;
  background-color: #fbf8f8;
  border-radius: 4px;
  margin: 30px 0;
  border: 1px solid gainsboro;
  border-radius: 5px;
  border-left: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap.blured {
  opacity: 0.6;
}

@media (max-width: 800px) {
  .listing-overview-item-panel-wrap .listing-overview-item-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-card-image-panel {
  border-radius: 5px 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 500px;
  height: 100%;
}

@media (max-width: 800px) {
  .listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-card-image-panel {
    width: 100% !important;
    height: 300px !important;
  }
}

@media (max-width: 700px) {
  .listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-card-image-panel {
    width: 100% !important;
    height: 200px !important;
  }
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-card-image-panel .listing-card-transparent-back {
  background-color: rgba(62, 62, 62, 0.8);
  color: white;
  padding: 10px;
  border-radius: 0 0 0 5px;
  border-top: 1px solid white;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview {
  padding: 10px 20px;
  width: 100%;
  height: 100%;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-price p {
  font-size: 18px;
  margin-bottom: 5px;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box {
  border: 1px solid gainsboro;
  padding: 5px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 5px 0;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-card-amenities {
  border-bottom: 1px solid gainsboro;
  padding: 5px;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-card-amenities .small {
  margin-right: 20px;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-overview-description-loved-wrap {
  width: 100%;
  height: 100%;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-overview-description-loved-wrap .listing-overview-description {
  padding: 10px 0;
  font-size: 12px;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-overview-description-loved-wrap .listing-overview-description p {
  display: -webkit-box;
  max-width: 80%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.listing-overview-item-panel-wrap .listing-overview-item-wrap .listing-overview .listing-overview-box .listing-overview-description-loved-wrap .loved-by-wrap {
  margin-top: 10px;
}

.hide {
  display: none;
}

.main-header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 10px;
  width: 100%;
}

.main-header-wrapper.shadow {
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 30px;
}

.main-header-wrapper .header-nav-bar, .main-header-wrapper .header-logo-nav-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1000;
  padding: 0 20px;
}

.main-header-wrapper .header-nav-bar .regular-menu, .main-header-wrapper .header-logo-nav-bar .regular-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}

@media (max-width: 1200px) {
  .main-header-wrapper .header-nav-bar .regular-menu, .main-header-wrapper .header-logo-nav-bar .regular-menu {
    display: none;
  }
}

.main-header-wrapper .header-nav-bar .back-button {
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  width: 100px;
  visibility: hidden;
}

.main-header-wrapper .header-nav-bar .back-button.visible {
  visibility: visible;
}

.button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 5px;
  border-radius: 3px;
  width: 150px;
  color: white;
}

.button-wrapper:hover {
  opacity: 0.7;
  cursor: pointer;
}

.button-wrapper.primary {
  background-color: #0073F0;
}

.button-wrapper.secondary {
  background-color: #FF5722;
}

.button-wrapper.gray {
  background-color: #979696;
}

.image-title-button-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 450px;
  height: 200px;
  border-radius: 5px;
}

@media (max-width: 1095px) {
  .image-title-button-wrapper {
    width: 350px;
  }
}

@media (max-width: 889px) {
  .image-title-button-wrapper {
    width: 250px;
  }
}

@media (max-width: 600px) {
  .image-title-button-wrapper {
    width: 100%;
  }
}

.logo-wrapper {
  width: 50px;
  background-color: #0073F0;
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
}

.footer-wrapper {
  width: 100%;
  background-color: #0073F0;
  color: white;
  width: 100%;
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 50px;
  clear: both;
}

@media (max-width: 580px) {
  .footer-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset;
    gap: 20px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.footer-wrapper .navigation-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.footer-wrapper .navigation-wrapper .menu-title {
  font-size: 16px;
  font-weight: bold;
}

.footer-wrapper .navigation-wrapper ul {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  width: 100%;
}

.footer-wrapper .navigation-wrapper ul li a {
  color: white;
}

.input-field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.input-field-wrapper.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.input-field-wrapper.row label {
  margin-right: 10px;
}

.input-field-wrapper.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.input-field-wrapper.column label {
  margin-right: 10px;
}

.input-field-wrapper .input-field-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border: 1px solid gray;
  border-radius: 3px;
  width: 100%;
  height: 31px;
}

.input-field-wrapper .input-field-icon p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  padding: 0;
  margin-right: 5px;
  color: #0073F0;
  height: 100%;
  width: 25px;
}

.input-field-wrapper .input-field-icon p * {
  width: 100%;
}

.input-field-wrapper .input-field-icon input {
  padding: 5px !important;
  border: none;
  outline: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.input-field-wrapper .input-field-icon .additional-info {
  background-color: #202020;
  border-radius: 0 3px 3px 0;
  color: white;
  font-weight: bold;
  padding: 5px;
}

.check-box-field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.check-box-field-wrapper .containter {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.check-box-field-wrapper .containter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-box-field-wrapper .containter .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
}

.check-box-field-wrapper .containter:hover input ~ .checkmark {
  background-color: #ccc;
}

.check-box-field-wrapper .containter input:checked ~ .checkmark {
  background-color: #FF5722 !important;
}

.check-box-field-wrapper .containter .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-box-field-wrapper .containter input:checked ~ .checkmark:after {
  display: block;
}

.check-box-field-wrapper .containter .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-box-field-wrapper.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.check-box-field-wrapper.row input {
  margin-right: 10px;
}

.check-box-field-wrapper.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.select-field-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.select-field-wrap.row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.select-field-wrap.row label {
  margin-right: 10px;
}

.select-field-wrap.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: space-between;
      -ms-flex-align: space-between;
          align-items: space-between;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.select-field-wrap.column label {
  margin-bottom: 10px;
}

.select-field-wrap .select-field {
  background-color: white !important;
  color: #3E3E3E;
  border: 1px solid #bebebe;
  border-radius: 5px;
  outline: none;
  padding: 3px;
  font-size: 11px;
  padding: 5px;
}

.small-info-box p {
  font-size: 12px;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  min-width: 50px;
  font-weight: bold;
}

.small-info-box p.big {
  font-size: 18px;
  padding: 3px;
}

.info-box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  background-color: #F8F8F8;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  color: #3E3E3E;
  min-width: 200px;
  gap: 10px;
}

.info-box-wrapper span {
  font-weight: bold;
  color: #FF5722;
}

.info-box-wrapper .black-text {
  color: black;
}

.info-box-wrapper .red-text {
  color: red;
}

.section-heading-wrap {
  margin-bottom: 20px;
}

.section-heading-wrap .section-heading {
  padding-bottom: 5px;
  margin: 10px 0;
  border-bottom: 1px solid #979696;
  font-weight: bold;
}

.main-filter-menu-wrap {
  width: 270px;
  border: 1px solid #bababa;
  border-radius: 0 3px 3px 0;
  background-color: white;
  color: #3E3E3E;
}

.type-select {
  width: 100%;
  margin: 5px 0;
}

.main-search-box-type-wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-search-box-type-wrap .select-field {
  width: 100% !important;
  height: 32px;
  margin: 5px 0;
}

.main-search-box-wrap {
  padding: 10px;
  background-color: white;
  color: #3E3E3E;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-search-box-wrap p {
  margin: 5px 0;
}

.main-search-box-wrap .main-search-box-selects-wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-search-box-wrap .main-search-box-selects-wrap .select-field {
  width: 120px;
  height: 32px;
  margin: 5px 0;
}

.main-search-box-wrap .main-search-box-selects-wrap .radio-group-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.main-search-box-wrap .search-button {
  margin-top: 5px;
  width: 100%;
  padding: 5px 0;
  font-size: 12px;
}

.main-search-box-wrap .span-style {
  width: 45%;
}

.checkboxes-dropdown-wrap .checkboxes-dropdown-header {
  width: 100%;
  padding: 5px 10px;
  background-color: #FF5722;
  color: white;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.checkboxes-dropdown-wrap .checkboxes-dropdown-header:hover {
  opacity: 0.8;
}

.checkboxes-dropdown-wrap .checkboxes-visible {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 5px 10px;
  font-size: 12px;
}

.announcements-wrap {
  padding: 10px;
  background-color: white;
  border: 1px solid #3E3E3E;
  border-radius: 3px;
}

.announcements-wrap span {
  margin-right: 10px;
}

.red {
  background-color: #ff9494;
}

.gray {
  background-color: rgba(255, 255, 255, 0.822);
}

.green {
  background-color: #abe098;
}

.map-size {
  width: "100%";
  height: "500px";
}

.new-listing-page-map-wrap .leaflet-container {
  width: 100%;
  min-height: 300px;
  height: 100%;
}

.map-size {
  width: "100%";
  height: "500px";
}

.listing-page-map-wrap .leaflet-container {
  width: 100%;
  min-height: 450px;
  height: 100%;
}

.mock-ad {
  width: 200px;
  height: 300px;
  border: 1px solid gray;
  margin: 20px 0;
}

.mock-ad img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.promoted-listings-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.promoted-listings-wrap .promoted-listings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (max-width: 1430px) {
  .promoted-listings-wrap .promoted-listings {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    gap: 20px;
  }
}

.promoted-listings-wrap .promoted-listings:last-child {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.login-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.login-wrapper .login {
  width: 250px;
}

.login-wrapper h2 {
  margin-bottom: 30px;
  font-weight: bold;
}

.login-wrapper .login-button {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  width: 100%;
}

.login-wrapper .social-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.login-wrapper .fb-button {
  background-color: #1877f2;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 250px;
  font-size: 14px;
  height: 39px !important;
}

.login-wrapper button, .login-wrapper .gg-button {
  width: 250px;
  height: 39px !important;
  border-radius: 5px;
  padding: 10px 20px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.login-wrapper .check-box-field-wrapper {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.login-wrapper p {
  margin-top: 20px;
}

.login-wrapper .login-icons-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.login-wrapper .login-icons-wrap .login-icon {
  color: #0073F0;
}

.login-wrapper .login-icons-wrap .login-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.main-page-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
}

.main-page-wrapper .main-page-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
}

.main-page-wrapper .main-page-content-wrapper .main-page-listings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.main-page-wrapper .main-page-content-wrapper .blog-shotcuts {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 20px;
}

.main-page-wrapper .main-page-content-wrapper .blog-shotcuts:last-child {
  justify-self: flex-start;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.new-listing-wrapper {
  height: 100%;
  color: black;
}

.new-listing-wrapper p {
  margin: 10px 0;
}

.new-listing-wrapper .new-listing-icons-wrap {
  margin-top: 20px;
}

.new-listing-wrapper .new-listing-icons-wrap .icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 10px 10px;
}

.new-listing-wrapper .new-listing-icons-wrap .icon-text-icon {
  width: 50px;
  height: 50px;
  color: #0073F0;
}

.new-listing-wrapper .new-listing-icons-wrap .icon-text-icon .MuiSvgIcon-root {
  font-size: 50px;
}

.new-listing-wrapper .new-listing-instructions {
  padding: 5px;
  background-color: #0073F0;
  color: white;
}

.new-listing-wrapper .new-listing-instructions .white {
  margin-left: 5px;
}

.listing-list-page-wrapper .listing-list-page-content-header {
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid #bababa;
}

.listing-list-page-wrapper .listing-list-page-content-header .orange {
  margin-left: 5px;
  font-weight: bold;
}

.listing-list-page-wrapper .listing-list-page-content-header .green-select .select-field {
  border: 1px solid lime !important;
}

.listing-list-page-wrapper .listings-list-pagination-wrap {
  height: 1200px;
  width: 100%;
}

.listing-list-page-wrapper .listings-list-pagination-wrap .listings-list-wrap {
  overflow: auto;
  width: 100%;
}

.new-listing-success-wrap {
  margin: 0 auto;
}

.new-listing-success-wrap .new-listing-success-message {
  margin-bottom: 30px;
  font-size: 20px;
}

.new-listing-success-wrap .new-listing-success-message p {
  margin: 10px;
}

.new-listing-success-wrap .new-listing-success-button-wrap {
  width: 100%;
}

.new-listing-success-wrap .new-listing-success-button-wrap .extra-wide-button {
  width: 250px !important;
}

.new-listing-success-wrap .info-box-wrapper {
  margin-bottom: 20px;
}

.user-data-page-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.user-data-page-wrap .user-data-sections-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media (max-width: 1000px) {
  .user-data-page-wrap .user-data-sections-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.user-data-page-wrap .user-data-wrap {
  width: 100%;
  padding: 20px;
  border-right: 1px solid #3E3E3E;
}

@media (max-width: 1000px) {
  .user-data-page-wrap .user-data-wrap {
    border-right: none;
  }
}

.user-data-page-wrap .user-data-wrap .select-field-wrap {
  margin-bottom: 10px;
}

.user-data-page-wrap .user-data-wrap .select-field-wrap select {
  width: 200px;
  height: 30px;
  border-radius: 3px;
}

.user-data-page-wrap .user-data-ps-wrap {
  padding: 20px;
  width: 400px;
}

.user-data-page-wrap .user-page-subtitle {
  margin-bottom: 10px;
}

.user-panel-contact-support-wrap {
  max-width: 500px;
  width: 100%;
  padding: 0;
}

.user-panel-contact-support-wrap .user-panel-contact-support-title {
  margin-bottom: 20px;
}

.user-panel-contact-support-wrap .input-field-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}

.user-panel-contact-support-wrap textarea, .user-panel-contact-support-wrap .input-field-icon {
  width: 300px;
  margin-bottom: 10px;
}

.user-message-wrap {
  margin: 20px;
  border: 1px solid #bababa;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
}

.user-message-wrap .user-message-checkbox-wrap {
  width: 50px;
  height: 100%;
}

.user-message-wrap .user-message-message-wrap {
  border-right: 1px solid #979696;
  border-left: 1px solid #979696;
  width: 100%;
  height: 100%;
  margin: 0 10px;
  padding: 0 10px;
}

.user-message-wrap .user-message-message-wrap div {
  width: 100%;
}

.user-message-wrap .user-message-buttons-wrap div {
  margin-left: 20px;
}

.new_lines {
  white-space: pre-wrap;
}

.bolded {
  font-weight: bold;
}

.user-inbox-wrap {
  height: 1200px;
}

.full-width {
  width: 100%;
}

.bold-text {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.user-panel-wrap .user-panel-content {
  background-color: #F8F8F8;
  border: 1px solid #bababa;
  color: #3E3E3E;
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.user-panel-wrap .announcements-list .title {
  margin-bottom: 20px;
}

.user-panel-wrap .announcements-list .user-announcement {
  margin-bottom: 20px;
}

.listing-page-wrap .font-light-gray {
  color: #979696;
}

.listing-page-wrap .page-wrap-header {
  border-top: 2px solid #0073F0;
  border-bottom: 1px solid #bababa;
  padding: 5px 0;
  width: 100%;
}

.listing-page-wrap .page-wrap-header .page-wrap-icons {
  margin-bottom: 10px;
}

.listing-page-wrap .page-wrap-header .page-wrap-icons .page-wrap-icon {
  margin-left: 10px;
}

.listing-page-wrap .listing-page-contact-publisher {
  margin: 10px 0;
  width: 100%;
  min-width: 300px;
}

.listing-page-wrap .listing-page-gallery-basic-info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-height: 600px;
  overflow: hidden;
}

@media (max-width: 915px) {
  .listing-page-wrap .listing-page-gallery-basic-info-wrap {
    overflow: visible;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: unset;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .listing-page-wrap .listing-page-gallery-basic-info-wrap .listing-page-basic-info {
    width: 100%;
  }
  .listing-page-wrap .listing-page-gallery-basic-info-wrap .listing-page-basic-info .listing-page-price .uppercase {
    font-size: 18px !important;
  }
}

@media (max-width: 550px) {
  .listing-page-wrap .listing-page-gallery-basic-info-wrap {
    margin: 0;
  }
  .listing-page-wrap .listing-page-gallery-basic-info-wrap .listing-page-contact-publisher {
    width: 100%;
  }
  .listing-page-wrap .listing-page-gallery-basic-info-wrap .listing-page-basic-info {
    margin: unset;
    width: 100%;
  }
}

.listing-page-wrap .listing-page-basic-info {
  border: 1px solid #bababa;
  padding: 10px;
  margin: 20px 0 20px 20px;
  border-radius: 4px;
}

.listing-page-wrap .listing-page-basic-info .font-light-gray {
  margin-right: 10px;
}

.listing-page-wrap .listing-page-basic-info .listing-page-price p {
  font-size: 25px !important;
  padding: 5px 10px;
}

.listing-page-wrap .listing-page-basic-info .listing-card-amenities {
  width: 100%;
  margin: 20px 0;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}

.listing-page-wrap .listing-page-location-legend-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 860px) {
  .listing-page-wrap .listing-page-location-legend-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .listing-page-wrap .listing-page-location-legend-wrap .listing-page-map-wrap {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .listing-page-wrap .listing-page-location-legend-wrap .section-heading-content {
    height: 100%;
    width: 100%;
  }
  .listing-page-wrap .listing-page-location-legend-wrap .listing-page-location-legend {
    margin-left: 0;
  }
}

.listing-page-wrap .listing-page-location-legend {
  height: 600px;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 20px;
  border-left: 3px solid #0073F0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.listing-page-wrap .listing-page-location-legend .icon-text-info {
  border: 1px solid #0073F0;
  border-radius: 2px;
  padding: 5px;
  color: #0073F0;
  min-width: 45%;
  margin: 5px 10px;
  width: 200px;
}

.listing-page-wrap .listing-page-location-legend .icon-text-info .icon-text-icon {
  margin-right: 10px;
}

.listing-page-wrap .listing-page-location-legend .icon-text-info .blue-b {
  padding: 5px 10px;
}

.listing-page-wrap .listing-page-map-wrap {
  height: 300px;
  width: 400px;
  border: 1px solid #979696;
}

.listing-page-wrap .carousel {
  max-height: 600px;
}

@media (max-width: 550px) {
  .listing-page-wrap .contact-publisher {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
  }
}

.information-page-wrap {
  padding: 0px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.information-page-wrap .information-page-background-image-wrap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.information-page-wrap .title {
  font-size: 30px;
  font-weight: bold;
  margin: 30px 0;
}

@media (max-width: 570px) {
  .information-page-wrap .title {
    font-size: 25px;
  }
}

@media (max-width: 320px) {
  .information-page-wrap .title {
    font-size: 20px;
  }
}

.information-page-wrap .information-page-content {
  padding: 0 50px;
}

@media (max-width: 1000px) {
  .information-page-wrap .information-page-content {
    padding: 0 20px;
  }
}

.dragging {
  color: red;
}

.new-listing-data {
  width: 100%;
}

.new-listing-data .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  padding: 0;
}

.new-listing-data .input-field-icon {
  height: 56px !important;
  width: 300px !important;
}

.new-listing-data .input-field-icon .additional-info {
  height: 56px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 30px;
}

.new-listing-data .general-info-box-wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.new-listing-data .general-info-box-wrap .new-listing-section {
  margin-bottom: 10px !important;
}

.new-listing-data .general-info-box-wrap .general-info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 10px;
  color: white;
  background-color: #FF5722;
  padding: 0 10px;
  font-size: 13px;
}

.new-listing-data select,
.new-listing-data .input-field-wrap {
  border-radius: 3px;
  height: 56px;
  width: 300px;
}

.new-listing-data input {
  width: 200px;
}

.new-listing-data .listing-general-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 5px;
}

.new-listing-data .listing-general-title {
  font-weight: bold;
}

.new-listing-data .listing-general-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.new-listing-data .listing-general-info .listing-general-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.new-listing-data .listing-general-info .listing-general-data .select-field-wrap select {
  width: 300px;
}

.new-listing-data .listing-general-info .new-listing-basic-data {
  width: 100%;
}

.new-listing-data .listing-general-info .new-listing-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.new-listing-data .listing-general-info .new-listing-item-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.new-listing-data .listing-general-info .new-listing-checkboxes-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 200px))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
}

.new-listing-data .listing-general-info .new-listing-checkboxes-box .checkbox-wrap {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 180px;
          flex: 1 1 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 5px;
}

.new-listing-data .listing-general-info .new-listing-checkboxes-box .checkbox-wrap p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.new-listing-data .listing-general-info .new-listing-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 50px;
}

.new-listing-data .listing-general-info .new-listing-contact-info .new-listing-contact-info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 10px;
}

.new-listing-data .listing-general-info .new-listing-contact-info .new-listing-contact-info-wrap select {
  width: 400px;
}

.new-listing-data .button-wrapper {
  -ms-flex-item-align: end;
      align-self: flex-end;
  justify-self: flex-end;
  margin-top: 20px;
}

.new-listing-data .new-listing-page-map-wrap {
  height: 300px;
  width: 800px;
  border: 1px solid #979696;
}

.listing-list-page-wrapper .listing-list-page-content-header {
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid #bababa;
}

.listing-list-page-wrapper .listing-list-page-content-header .orange {
  margin-left: 5px;
  font-weight: bold;
}

.listing-list-page-wrapper .listing-list-page-content-header .green-select .select-field {
  border: 1px solid lime !important;
}

.listing-list-page-wrapper .listings-list-pagination-wrap {
  height: 1200px;
  width: 100%;
}

.listing-list-page-wrapper .rowGreen {
  background-color: #ccffcc;
}

.listing-list-page-wrapper .rowRed {
  background-color: #ffcccc;
}

.not-found-wrap {
  margin: 0 auto;
}

.not-found-wrap .not-found-message {
  margin-bottom: 30px;
  font-size: 20px;
}

.not-found-wrap .not-found-message p {
  margin: 10px;
}

.page-wrap {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 50px 20px;
}

.App {
  position: relative;
}

.App .content-wrap {
  width: 100%;
}

.App .app-content-wrap {
  padding-bottom: 20px;
  min-height: 100vh;
}

.App .main-page-image-search {
  height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App .main-page-image-search .image-dark-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 0;
  margin: 0;
}

.App .main-page-image-search p {
  font-size: 40px;
  max-width: 400px;
}

.App .main-page-image-search .main-page-search-box {
  padding: 10px;
  border: 1px solid #bababa;
  border-radius: 4px;
  width: 270px;
  height: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 100px;
  background-color: white;
}

@media (max-width: 950px) {
  .App .main-page-image-search .main-page-search-box {
    display: none;
  }
}

.App .main-page-image-search .main-page-search-box p {
  font-size: 16px;
}

.App .main-page-image-search .main-page-search-box .select-field {
  width: 120px;
}

.App .filter-sidebar-wrapper {
  left: 0;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .App .filter-sidebar-wrapper .user-navigation-wrap {
    display: none;
  }
}

.App .side-menu-ads-sidebar-wrapper {
  width: 200px;
  right: 0;
  top: 0px;
  margin: 0;
  margin-top: 50px;
}

@media (max-width: 700px) {
  .App .side-menu-ads-sidebar-wrapper {
    display: none;
    width: unset;
    left: 0;
  }
}

.App .side-menu-ads-sidebar-wrapper .navigation-wrapper {
  margin: 0;
  padding: 0;
  background-color: #0073F0;
}

@media (max-width: 1200px) {
  .App .side-menu-ads-sidebar-wrapper .navigation-wrapper {
    display: none;
  }
}

.App .side-menu-ads-sidebar-wrapper .navigation-wrapper ul {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.App .side-menu-ads-sidebar-wrapper .navigation-wrapper ul li {
  color: white;
  border-bottom: 1px solid white;
  padding: 10px;
  width: 100%;
}

.App .side-menu-ads-sidebar-wrapper .navigation-wrapper ul li a {
  color: white;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(189, 189, 189, 0.3);
  background-color: #999999;
  cursor: pointer;
}
