
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $font;
    font-weight: $regularWeight;
}

html, body {
    padding: 0;
    margin: 0;

}

.clickable {
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

//LINKS TEXT FONTS transformations
a {
    text-decoration: none;
}

.bold {
    font-weight: bold;
}

.xs {
    font-size: 11px;
}
.small {
    font-size: 12px;
}
.pre-regular {
    font-size: 14px;
}
.regular {
    font-size: 16px;
}
.medium {
    font-size: 18px;
}
.big {
    font-size: 20px;
}
.uppercase {
    text-transform: uppercase;
}

//COLORS
.white {
    color: white;
}
.black {
    color: black;
}
.light-gray {
    color: $shade-light;
}

.orange {
    color: $accentColor2;
}

.blue {
    color: $accentColor0;
}

.green {
    color: $confirmation;
}

.green-b {
    background-color: $confirmation;
    color: white;
}
.orange-b {
    background-color:$accentColor2;
    color: white;
}
.blue-b {
    background-color: $accentColor0;
    color: white;
}
.purple-b {
    background-color: $premium;
    color: white;
}

//POSITIONING

.flex {
    display:flex;
    &.row{
        flex-direction: row;
    }
    &.column{
        flex-direction: column;
    }
    &.a-start {
        align-items: flex-start;
    }
    &.a-center {
        align-items: center;
    }
    &.a-end {
        align-items: flex-end;
    }
    &.a-sb {
        align-items: space-between;
    }
    &.a-evenly {
        align-items: space-evenly;
    }
    &.jc-start {
        justify-content: flex-start;
    }
    &.jc-center {
        justify-content: center;
    }
    &.jc-end {
        justify-content: flex-end;
    }
    &.jc-sb {
        justify-content: space-between;
    }
    &.jc-evenly {
        justify-content: space-evenly;
    }
}

//CONTENT SIZE
.full-width {
    width: 100%;
}
.full-height {
    height: 100%;
}
.full-size {
    width: 100%;
    height: 100%;
}